/**
 * visual
 */

.col-visual {
  height: calc(100vh + 34rem);
  position: relative;
  background: linear-gradient(to bottom,
    rgba($color: $white, $alpha: 0) 100vh,
    rgba($color: $white, $alpha: 0.8) calc(100vh + 26rem),
    rgba($color: $white, $alpha: 1) calc(100vh + 26rem)
  );
  @include mq_down(sp) {
    height: inherit;
    background: none;
  }
  .col-lead {
    margin: 0 $space;
    @include mq_up(sp) {
      position: absolute;
      top: 50vh;
      transform: translate(0, -50%);  
    }
    @include mq_down(sp) {
      padding: 16rem 0 10rem;
    }
    .col-lead-item {
      display: inline-block;
      &:nth-of-type(2) {
        margin-left: 1em;
      }
    }
    svg {
      width: 1.4em;
      position: absolute;
      margin: -0.3em 0 0 -0.4em;
      fill: $pink;
    }
    small {
      position: relative;
    }
  }
  .slick-dotted.slick-slider {
    margin-bottom: 0;
  }
  // slide-news
  .col-slide-news {
    padding-bottom: 4rem;
    background: $white;
    overflow: hidden;
    box-shadow: $shadow;
    position: relative;
    z-index: 1;
    @include mq_up(sp) {
      width: 45%;
      max-width: 80rem;
      min-width: 56rem;
      position: absolute;
      top: calc(100vh - 6rem);
      right: 0;
      border-radius: #{$radius} 0 0 0 / #{$radius} 0 0 0;
    }
    .col-item {
      display: flex;
      height: 100%;
      strong {
        display: flex;
        justify-content: space-between;
        background: $black;
        color: $white;
        height: 6rem;
        line-height: 6rem;
        padding: 0 4rem;
        font-size: 1.8rem;
        @include mq_down(sp) {
          padding: 0 $space;
        }
        time {
          font-size: 1.4rem;
          font-weight: normal;
          letter-spacing: 0.05em;
        }
      }
      a {
        display: block;
        height: 100%;
        padding: 3rem 4rem;
        line-height: 2;
        @include mq_down(sp) {
          padding: 3rem $space;
        }
      }
    }
    .slick-dots {
      bottom: 4rem;
      @include mq_up(sp) {
        text-align: inherit;
        padding-left: 3.5rem;  
      }
    }
  }
}

/**
 * section 
 */

[class*="col-section"] {
  position: relative;
  margin-bottom: $space * 2;
  @include mq_down(sp) {
    margin-bottom: 10rem;
  }
  &::before {
    content: "";
    width: $space * 15;
    height: 100%;
    position: absolute;
    background: $grayf1;
  }
  .col-inner {
    @include siteBox;//_utility.scss
    position: relative;
    padding: 0 $space;
    .col-lead {
      position: relative;
      top: -0.5em;
      margin-bottom: 0;
      z-index: 1;
      @include mq_up(sp) {
        margin: 0 $space;
      }
    }
    .col-button {
      position: relative;
      margin: 4em auto 0;
      bottom: -2.5rem;
      @include mq_down(sp) {
        margin: 0 auto;
      }
    }
  }
  [class*="col-column2"] {
    li {
      a {
        h3 {
          margin: 1.5em $space 0;
          font-size: 2.4rem;
          @include mq_down(sp) {
            margin: 1.5em 0 0;
            font-size: 1.8rem;
          }
        }
        p {
          margin: 1.5em $space 0;
          @include mq_down(sp) {
            margin: 1em 0;
          }
        }
      }
    }
  }
}

/**
 * service
 */

.col-section-service {
  margin-bottom: $space * 3;
  @include mq_down(sp) {
    margin-bottom: 10rem;
  }
  &::before {
    background: $lightgreen;
  }
  .col-inner {
    @include mq_down(sp) {
      margin-top: 6rem;
    }
  }
}

/**
 * works
 */

.col-section-works {
  &::before {
    right: 0;
    background: $lightyellow;
  }
  .col-inner {
    .col-lead {
      @include mq_up(sp) {
        margin-bottom: 20%;
      }
    }
    .col-image {
      position: relative;
      @include mq_up(sp) {
        width: 90%;
        position: absolute;
        top: -12rem;
        right: -10rem;
      }
      @include mq_down(sp) {
        width: 110%;
        top: -2rem;
        left: -5%;
      }
    }
    .col-column3 {
      margin: 0 $space;
      @include mq_down(sp) {
        margin: 0;
      }
    }
  }
}

/**
 * list-workslogo
 */

.col-list-logo {
  @include siteBox;//_utility.scss
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: $space * 2;
  padding: 0 $space * 2;
  @include mq_down(sp) {
    margin-bottom: 10rem;
    padding: 0 $space;
  }
  li {
    display: flex;
    width: calc(100% / 6 - 2rem);
    overflow: hidden;
    margin-bottom: 1em;
    padding: 0 1rem;
    @include mq_down(sp) {
      width: calc(100% / 3 - 2rem);
      padding: 0;
    }
    a {
      display: flex;
      justify-content: center;
      align-items: center;
        width: 100%;
      img {
        max-height: 6.4rem;
      }
    }
  }
}

/**
 * aboutus
 */

.col-section-aboutus {
  &::before {
    width: $space * 13;
    right: $space * 2;
    background: $lightpink;
    @include mq_down(sp) {
      width: $space * 15;
      right: 0;
    }
  }
  .col-inner {
    padding-bottom: 24rem;
    @include mq_down(sp) {
      padding-bottom: 4rem;
    }
    .col-lead {
      text-align: right;
    }
    figure {
      border-radius: $radius;
      @include mq_up(sp) {
        max-width: 60%;
        margin-left: $space;
      }
      @include mq_down(sp) {
        margin-bottom: $space;
      }
    }
    .col-text {
      position: relative;
      padding: 5rem 6rem;
      background: $white;
      @include mq_up(sp) {
        width: 56rem;
        max-width: 60%;
        position: absolute;
        right: $space * 4;
        bottom: $space * 2;
      }
      @include mq_down(sp) {
        padding: $space * 2 $space;
      }
      .col-button {
        position: absolute;
        @include mq_up(sp) {
          right: 6rem;
        }
        @include mq_down(sp) {
          right: calc(50% - 12rem);
        }
      }
    }
  }
}

/**
 * blog
 */

.col-section-blog {
  &::before {
    background: $lightpurpleblue;
  }
  .col-column3 {
    li {
      &:nth-child(n + 4) {
        @include mq_down(sp) {
          display: none;
        }
      }
    }
  }
}

/**
 * news
 */

.col-section-news {
  &::before {
    right: 0;
    background: $lightpurplered;
  }
  .col-inner {
    .col-lead {
      margin: 0 $space * 3;
      @include mq_down(sp) {
        margin: 0;
      }
    }
  }
  .col-list-news {
    li {
      background: $white;
    }
  }
}
