/**
 * box image
 */

.col-box-image {
  display: block;
  position: relative;
  padding: $space * 1.5 $space * 2;
  overflow: hidden;
  max-width: 160rem;
  margin: 6rem auto 8rem;
  background: $blackdark;
  @include mq_down(sp) {
    margin: 4rem 0;
    padding: $space * 2 $space * 1.5;
  }
  img {
    width: 100%;
    min-height: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    @include mq_down(sp) {
      width: inherit;
      max-width: unset;
    }
    &.col-image-top {
      top: 0;
      transform: translate(-50%, 0);
    }
  }
  .col-inner {
    position: relative;
    color: $white;
  }
  .col-button-line {
    @include mq_down(sp) {
      margin: auto;
    }
  }
  &.col-text-left,
  &.col-text-right {
    &::before {
      content: "";
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background: linear-gradient(to right, rgba($color: $blackdark, $alpha: 0.75) 40%, rgba($color: $blackdark, $alpha: 0) 60%);
      z-index: 1;
      transition: $animation;
      @include mq_down(sp) {
        background: rgba($color: $blackdark, $alpha: 0.75);
      }
    }
    .col-text {
      position: relative;
      width: 45%;
      font-size: 1.5rem;
      z-index: 1;
      @include mq_down(sp) {
        width: inherit;
        min-width: inherit;
      }
    }
  }
  &.col-text-right {
    &::before {
      background: linear-gradient(to right, rgba($color: $blackdark, $alpha: 0) 40%, rgba($color: $blackdark, $alpha: 0.75) 60%);
      @include mq_down(sp) {
        background: rgba($color: $blackdark, $alpha: 0.75);
      }
    }
    .col-text {
      float: right;
    }
  }
  &:hover {
    img {
      opacity: 0.75;
    }
    .col-button-line {
      background: $white;
      color: $black;
      svg {
        fill: $black;
      }
    }
  }
}

/**
 * box min
 */

.col-box-body {
  max-width: $siteMin;
  margin: auto;
}