/**
 * itemlist
 */

[class*="col-column3"] {
  &[class*="-itemlist"] {
    border-top: solid $graydd 1px;
    margin-top: 6rem;
    padding-top: 6rem;
    @include mq_down(mb) {
      margin-top: 4rem;
      margin-bottom: 4rem;
      padding-top: 0;
    }
    &:last-child {
      margin-bottom: 0;
    }
    li {
      display: flex;
      align-items: flex-start;
      margin-bottom: 2em;
      position: relative;
      pointer-events: none;
      @include mq_down(mb) {
        margin: 0;
        padding: 1em 2em 1em 0;
        border-bottom: solid $graydd 1px;
        cursor: pointer;
        pointer-events: inherit;
      }
      img {
        display: block;
        width: 4rem;
        margin-right: 1.5em;
      }
      .col-text {
        @include mq_down(mb) {
          @include childMargin;//_utility.scss
        }
        h4 {
          @include mq_down(sp) {
            padding-top: 0.5em;
            color: $green;
          }
        }
        p {
          display: initial;
          @include mq_down(sp) {
            display: none;
          }
        }
      }
      .col-icon-plus {
        display: none;
        top: calc(1.25em + 1rem);
        right: 0;
        @include mq_down(mb) {
          display: inline-block;
        }
      }
    }
  }
  &[class*="-notop"] {
    margin-top: 0;
  }
}

/**
 * provide
 */

.col-service-provide {
  width: 100%;
  height: 40rem;
  position: relative;
  @include mq_down(sp) {
    max-width: 30rem;
    height: 30rem;
    margin: 0 auto!important;
  }
  span {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 15vw;
    height: 15vw;
    max-width: 20rem;
    max-height: 20rem;
    position: absolute;
    font-weight: 600;
    @include mq_down(sp) {
      width: 14rem;
      height: 14rem;
    }
    img {
      position: absolute;
      top: 0;
      left: 0;
      animation: spin 60s linear infinite;
      pointer-events: none;
    }
    @keyframes spin {
      0% {transform: rotate(0deg);}
      100% {transform: rotate(-360deg);}
    }
    &.col-item-red {
      top: 15%;
      left: 0;
    }
    &.col-item-blue {
      right: 30%;
      bottom: 0;
      @include mq_down(sp) {
        right: 9%;
      }
    }
    &.col-item-yellow {
      top: 0;
      right: 0;
    }
  }
}