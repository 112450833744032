/**
 * nav
 */

.col-nav {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 8rem;
  height: 100vh;
  background: $grayf1;
  text-align: center;
  position: fixed;
  z-index: 10;
  border-right: solid rgba($color: $white, $alpha: 0.2) 1px;
  transition: $animation;
  @include mq_down(sp) {
    width: 6rem;
    height: 6rem;
    top: 1.5em;
    right: calc(#{$space} - 1em);
    background: none;
    border: none;
  }
  .col-open {
    @include posCenter;//_utility.scss
    display: block;
    width: 100%;
    padding: 25%;
    font-family: 'Inter';
    font-size: 1.1rem;
    font-family: $fontSpecific;
    font-weight: 500;
    letter-spacing: 0.05em;
    cursor: pointer;
    z-index: 2;
    i {
      display: block;
      background: $black;
      width: 100%;
      height: 2px;
      position: relative;
      margin: 1em 0 1.5em;
      letter-spacing: 0.05em;
      transition: $animation;
      @include mq_down(sp) {
        height: 3px;
        border-radius: 2px;
      }
      &::before,
      &::after {
        content: "";
        width: 100%;
        height: 100%;
        position: absolute;
        background: $black;
        left: 0;
        transition: $animation;
        @include mq_down(sp) {
          border-radius: 2px;
        }
      }
      &::before {top: -1em;}
      &::after {bottom: -1em;}
    }
    span {
      @include mq_down(sp) {
        display: none;
      }
    }
    &:hover {
      opacity: 0.75;
      color: $black;
    }
  }
  .col-sns {
    margin-bottom: 2em;
    @include mq_down(sp) {
      display: none;
    }
    li {
      a {
        display: block;
        padding: 0.5em;
        svg {
          width: 1.8rem;
          fill: $black;
          transition: $animation;
        }
        &:hover {
          opacity: 0.75;
        }
      }
    }
  }
  // nav-area
  .col-nav-area {
    background: $black;
    color: $white;
    position: absolute;
    top: 0;
    left: 8rem;
    width: calc(100vw - 8rem);
    height: 100vh;
    padding: 6rem 8rem;
    box-sizing: $space;
    text-align: left;
    overflow: scroll;
    opacity: 0;
    pointer-events: none;
    transition: $animation;
    @include mq_down(sp) {
      position: fixed;
      top: 0;
      left: 0;
      width: 100vw;
      padding: 2em $space;
    }
    .col-logo {
      width: 20rem;
      display: block;
      margin-bottom: 6rem;
      @include mq_down(sp) {
        width: 16rem;
      }
      svg {
        fill: $white;
      }
    }
    .col-nav-section {
      display: flex;
      flex-wrap: wrap;
      a {
        color: $white;
        &:hover {
          opacity: 0.75;
          img, svg {
            opacity: 1;
          }
        }
      }
      dl {
        dt {
          font-family: $fontSpecific;
          margin-bottom: 3rem;
          opacity: 0.5;
        }
        dd {
          margin-bottom: 6rem;
        }
      }
      .col-nav-menu {
        width: 35%;
        @include mq_down(sp) {
          width: 100%;
        }
        ul {
          @include mq_down(sp) {
            display: flex;
            flex-wrap: wrap;
          }
          li {
            margin-bottom: 2em;
            line-height: 1;
            @include mq_down(sp) {
              width: 50%;
            }
            a {
              font-size: 4rem;
              font-family: $fontSpecific;
              font-weight: 500;
              letter-spacing: 0.05em;
              @include mq_down(sp) {
                font-size: 3rem;
              }
              small {
                display: block;
                margin-top: 0.5em;
                font-size: 1.2rem;
              }
            }
          }
        }
      }
      .col-nav-link {
        width: 65%;
        @include mq_down(sp) {
          width: 100%;
        }
        ul {
          li {
            margin-bottom: 2em;
            a {
              display: block;
              overflow: hidden;
              figure {
                width: 16rem;
                float: left;
                border-radius: $radius / 2;
                margin-right: 2em;
                @include mq_down(sp) {
                  width: 12rem;
                }
              }
              .ol-text {
                font-weight: bold;
              }
              .col-info {
                @include mq_down(sp) {
                  padding: 1em 0 0;
                  clear: both;
                }
                span {
                  color: rgba($color: $white, $alpha: 0.5);
                }
              }
            }
          }
        }
      }
      .col-nav-pickup {
        .col-trim {
          border-radius: $radius / 2;
          &::before {
            padding-top: 30%;
          }
        }
        a {
          display: block;
          margin-bottom: 2em;
          p {
            margin-top: 1em;
          }  
        }
      }
    }
  }
  // 背景の自動取得後
  &.background--dark {
    .col-open {
      i {
        @include mq_down(sp) {
          background: $white;
          &::before,
          &::after {
            background: $white;
          }  
        }
      }
    }
  }
  // open
  &.js-open {
    background: $black;
    @include mq_down(sp) {
      background: none;
    }
    .col-open {
      color: $white;
      i {
        background: none;
        &::before,
        &::after {
          background: $white;
        }
        &::before {
          top: inherit;
          transform: rotate(45deg);
        }
        &::after {
          bottom: inherit;
          transform: rotate(135deg);
        }
      }
    }
    .col-sns {
      li {
        a {
          svg {
            fill: $white;
          }
        }
      }
    }
    .col-nav-area {
      opacity: 1;
      pointer-events: inherit;
    }
  }
}

/**
 * nav-header
 */

.col-nav-header {
  display: flex;
  width: calc(100% - 8rem);
  position: fixed;
  top: 0;
  left: 8rem;
  padding: 2em calc((100% - 8rem) * 0.05);
  z-index: 2;
  transition: $animation;
  @include mq_down(sp) {
    width: inherit;
    left: 0;
    padding: 2em $space;
  }
  .col-logo {
    width: 20rem;
    height: 3.6rem;
    margin-right: auto;
    [class*="path"] {
      transition: $animation;
    }
    .path { fill:#353035;}
    .path-pink { fill: #d67e87;}
    .path-blue { fill: #61b0d4;}
    .path-green { fill: #BACB80;}
    @include mq_down(sp) {
      width: 16rem;
    }
  }
  ul {
    display: flex;
    @include mq_down(sp) {
      display: none;
    }
    * {
      display: flex;
    }
    li {
      margin-left: 2em;
      a {
        align-items: center;
        font-weight: 600;
        font-family: $fontSpecific;
        letter-spacing: 0.02em;
        font-size: 1.8rem;
        &:hover {
          color: $black;
          opacity: 0.5;
        }
      }
      &.col-lang {
        a {
          &:hover {
            opacity: 1;
          }
        }
      }
    }
  }
  &.js-hide {
    @include mq_up(sp) {
      transform: translate(0, -100%);
    }
  }
  // 背景の自動取得後
  &.background--dark {
    .col-logo {
      .path,
      .path-pink,
      .path-blue,
      .path-green{ fill: $white;}
    }
    ul {
      li {
        a {
          color: $white;
        }
      }
    }
  }
}