/**
 * banner
 */

.col-banner {
  ul {
    display: flex;
    flex-wrap: wrap;
    li {
      width: 50%;
      @include mq_down(sp) {
        width: 100%;
      }
      &.col-3rdfocus {
        background: url(/assets/img/common/bg-3rdfocus.png) no-repeat;
        background-size: cover;
      }
      &.col-platform {
        background: url(/assets/img/common/bg-platform.png) no-repeat;
        background-size: cover;
      }
      a {
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        padding: 4rem;
        min-height: 40rem;
        @include mq_down(sp) {
          min-height: 24rem;
        }
        img {
          max-height: 4rem;
          margin-bottom: 1em;
          @include mq_down(sp) {
            max-height: 3rem;
          }
        }
        p {
          font-size: 1.4rem;
          @include mq_down(sp) {
            font-size: 1.2rem;
          }
        }
        &:hover {
          color: $black;
        }
      }
    }
  }
}

/**
 * gotop
 */

.col-gotop {
  display: block;
  width: 6rem;
  height: 6rem;
  background: $white;
  position: fixed;
  right: 2rem;
  bottom: 2rem;
  z-index: 10;
  border-radius: 50%;
  opacity: 0;
  pointer-events: none;
  @include mq_down(mb) {
    width: 4rem;
    height: 4rem;
    right: $space;
    bottom: 2rem;
  }
  i {
    display: block;
    width: 20%;
    height: 20%;
    margin: 40%;
    border-top: solid $black 3px;
    border-left: solid $black 3px;
    text-indent: 100%;
    white-space: nowrap;
    overflow: hidden;
    transition: $animation;
    transform: rotate(45deg) translate(10%, 10%);
  }
  &:hover {
    i {
      transform: rotate(45deg) translate(-10%, -10%);
    }
  }
  &.js-trigger {
    pointer-events: inherit;
    opacity: 1;
  }
}

/**
 * footer
 */

footer {
  // contact
  .col-contact {
    display: block;
    background: $yellow;
    .col-inner {
      @include siteBox;//_utility.scss
      padding: $space * 1.2 $space * 2;
      position: relative;
      transition: $animation;
      @include mq_down(mb) {
        text-align: center;
        padding: $space * 2.4 $space * 2;
      }
      h2 {
        position: relative;
        margin-bottom: 0;
        @include mq_down(mb) {
          padding-bottom: 5rem;
        }
        svg {
          width: 1.2em;
          margin: -0.15em 0.5em 0 0;
          vertical-align: middle;
          fill: $black;
        }
        small {
          display: block;
          margin-top: 3rem;
          font-size: 1.4rem;
          font-weight: normal;
        }
        .col-arrow-right {
          width: 4.8rem;
          height: 2.4rem;
          position: absolute;
          top: inherit;
          left: inherit;
          bottom: calc(50% - 1.2rem);
          right: 0;
          margin: 0;
          @include mq_down(mb) {
            width: 3.6rem;
            bottom: 0;
            right: calc(50% - 1.8rem);
          }
        }
      }
    }
    &:hover {
      color: $black;
      .col-inner {
        h2 {
          svg {
            opacity: 1;
            &:not(.col-arrow-right) {
              transform: rotateY(180deg);
            }
          }
        }
      }
    }
  }
  // nav-footer
  .col-nav-footer {
    background: $black;
    color: $white;
    a {
      color: $white;
    }
    .col-inner {
      @include siteBox;//_utility.scss
      display: flex;
      font-size: 1.4rem;
      @include mq_down(sp) {
        flex-wrap: wrap;
      }
      .col-text {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 20rem;
        margin-right: $space;
        @include mq_down(sp) {
          order: 2;
          margin-top: 2em;
        }
        dt {
          @include mq_down(sp) {
            margin-bottom: 4em;
          }
          svg {
            fill: $white;
            margin-bottom: 1em;
          }
        }
        .col-copy {
          font-size: 1.2rem;
          opacity: 0.5;
        }
      }
      .col-link {
        @include mq_down(sp) {
          width: 100%;
          order: 1;
        }
        ul {
          display: flex;
          flex-wrap: wrap;
          margin-bottom: 1em;
          padding-bottom: 1em;
          border-bottom: solid rgba($color: $white, $alpha: 0.1) 1px;
          &:last-of-type {
            border: none;
            margin-bottom: 0;
            @include mq_down(sp) {
              border-bottom: solid rgba($color: $white, $alpha: 0.1) 1px;
            }
          }
          li {
            margin: 0.5em 2em 0.5em 0;
            @include mq_down(sp) {
              width: 50%;
              margin: 0.5em 0;
            }
            &:last-child {
              margin-right: 0;
            }
            &.col-title {
              width: 8rem;
              font-size: 1.4rem;
              font-weight: normal;
              opacity: 0.5;
              margin-bottom: 0.5em;
              @include mq_down(sp) {
                width: 100%;
              }
            }
            a {
              &:hover {
                opacity: 0.5;
              }
            }
          }
        }
      }
    }
  }
}
