/**
 * slide works
 */

.col-slide-works {
  position: relative;
  .slick-slide {
    padding: 0 2rem;
    outline: none;
    @include mq_down(sp) {
      padding: 0 1rem;
    }
    img {
      width: inherit!important;
      height: 45rem;
      @include mq_down(mb) {
        height: 20rem;
      }
    }
  }
  .slick-dots {
    bottom: -4rem;
  }
}

/**
 * work lead
 */

.col-work-lead {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  max-width: 112rem;
  margin: 0 auto 6rem;
  padding-bottom: 4rem;
  border-bottom: solid $grayf1 1px;
  h1 {
    width: 100%;
    font-size: 3.6rem;
    font-weight: 500;
    margin-bottom: 1em;
    @include mq_down(sp) {
      font-size: 2.4rem;
    }
  }
  .col-text {
    flex: 1;
    @include mq_down(sp) {
      width: 100%;
    }
  }
  dl {
    width: 40%;
    margin: 0.4em 0 0 $space;
    display: flex;
    flex-wrap: wrap;
    font-size: 1.4rem;
    @include mq_down(sp) {
      width: 100%;
      margin: 0;
    }
    dt {
      font-weight: bold;
      width: 5em;
      margin-bottom: 1em;
    }
    dd {
      width: calc(100% - 5em);
      margin-bottom: 1em;
      word-break: break-all;
      color: $gray66;
    }
  }
  a {
    color: $green;
    &:hover {
      opacity: 0.75;
    }
  }
}