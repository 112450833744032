/**
 * lead recruit
 */

.col-lead-recruit {
  position: relative;
  margin-bottom: 8rem;
  padding: 4rem 0;
  @include mq_down(sp) {
    margin-bottom: 4rem;
    padding: 0 0 4rem;
  }
  h2 {
    text-align: center;
    margin-bottom: 1.5em;
  }
  .col-box-body {
    position: relative;
    z-index: 1;
    .col-text {
      font-size: 1.7rem;
      @include mq_down(sp) {
        font-size: 1.5rem;
      }
      @include mq_down(sp) {
        background: rgba($color: $white, $alpha: 0.75);
      }
      /*
      background: rgba($color: $white, $alpha: 0.75);
      box-shadow: 0 0 5rem rgba(255, 255, 255, 1);
      */
    }
  }
  figure {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    .col-item-red,
    .col-item-blue,
    .col-item-yellow {
      position: absolute;
      @include mq_down(sp) {
        max-width: 24rem;
      }
      @include mq_down(sp) {
        max-width: 15rem;
      }
    }
    img {
      animation: spin 60s linear infinite;
      pointer-events: none;
    }
    @keyframes spin {
      0% {transform: rotate(0deg);}
      100% {transform: rotate(-360deg);}
    }
    .col-item-red {
      top: 35%;
      left: 0;
      @include mq_down(sp) {
        left: -5%;
      }
      @include mq_down(mb) {
        top: 10%;
        left: -20%;
      }
    }
    .col-item-blue {
      right: 0;
      bottom: -5%;
      @include mq_down(sp) {
        right: -5%;
      }
      @include mq_down(mb) {
        right: -10%;
      }
    }
    .col-item-yellow {
      top: -5%;
      right: 8%;
      @include mq_down(sp) {
        top: 0;
        right: 0;
      }
      @include mq_down(mb) {
        right: -10%;
      }
    }
  }
}

/**
 * title margin
 */

#col-recruit-occupation {
  margin-top: 2rem;
  @include mq_down(sp) {
    margin-top: 6rem;
  }
}

/**
 * occupation
 */

.col-occupation {
  border-top: solid $graydd 1px;
  padding-top: 6rem;
  @include mq_down(sp) {
    padding-top: 4rem;
  }
  .col-inner {
    position: relative;
    max-width: 80rem;
    margin: 0 auto 6rem;
    @include mq_down(sp) {
      margin: 0 auto 4rem;
    }
    &:first-child {
      .col-line {
        display: none;
      }
    }
    .col-line {
      margin: 0 -10% 3em;
    }
  }
  // occupation
  .col-table-occupation {
    display: none;
    background: $grayf7;
    padding: 0 $space * 1.5 4rem;
    @include mq_down(sp) {
      padding: 0 $space 4rem;
    }
    table {
      width: 100%;
      margin-bottom: 4rem;
      tr {
        border-bottom: solid $graydd 1px;
      }
      th, td {
        padding: 2em 0;
        line-height: 2;
        font-size: 1.4rem;
        @include mq_down(sp) {
          display: block;
        }
      }
      th {
        width: 25%;
        font-weight: 500;
        text-align: left;
        line-height: 1.4;
        @include mq_down(sp) {
          width: inherit;
          padding-bottom: 1em;
        }
      }
      td {
        @include mq_down(sp) {
          padding-top: 0;
        }
      }
      ul {
        li {
          &::before {
            content: "−";
            margin-right: 0.75em;
          }
        }
      }
    }
  }
  .col-toggle {
    display: block;
    padding: 1.5em;
    text-align: center;
    position: relative;
    color: $green;
    font-weight: bold;
    font-size: 1.4rem;
    cursor: pointer;
    background: $grayf1;
    @include mq_down(sp) {
      font-size: 1.3rem;
      padding: 1.2em;
    }
    span {
      display: inline-block;
      text-align: center;
      width: 6em;
      &::before {
        content: "詳しく見る";
      }
    }
    .col-icon-plus {
      position: relative;
      margin-left: 1em;
      top: -1px;
    }
    &:hover {
      background: $green;
      color: $white;
    }
    &.js-on {
      span {
        &::before {
          content: "詳細を閉じる";
        }
      }
    }
  }
}
