/**
 * list news
 */

.col-list-news {
  margin: 0 $space * 2;
  @include mq_down(sp) {
    margin: 0;
  }
  li {
    background: $grayf7;
    transition: $animation;
    &:hover {
      transform: scale(1.02);
    }
    a {
      display: flex;
      padding: 1.2em;
      margin-bottom: 0.4rem;
      align-items: center;
      @include mq_down(sp) {
        justify-content: space-between;
        flex-wrap: wrap;
      }
      time {
        width: 6.5em;
      }
      .col-tag {
        display: inline-block;
        width: 10em;
        text-align: center;
        margin-right: 1.5em;
        background: $white;
        border: solid $green 1px;
        font-size: 1.2rem;
        color: $green;
        transition: $animation;
      }
      p {
        margin-bottom: 0;
        flex: 1;
        @include mq_down(sp) {
          flex: none;
          width: 100%;
          margin-top: 1em;
        }
      }
    }
  }
}