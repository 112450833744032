/**
 * column
 */

[class*="col-column"] {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  position: relative;
  li {
    @include childMargin;//_utility.scss
    width: 100%;
    @include mq_down(sp) {
      margin-bottom: 1.5em;
    }
    a {
      display: block;
      position: relative;
    }
    figure {
      border-radius: $radius;
      + h3 {
        margin-top: 1em;
        font-size: 1.6rem;
      }
    }
    p {
      font-size: 1.5rem;
    }
    .col-info {
      margin-top: 2em;
    }
  }
  // pattern-reverse
  &[class*="-reverse"] {
    li {
      &:nth-child(1) {
        @include mq_down(mb) {
          order: 2;
        }
      }
      &:nth-child(2) {
        @include mq_down(mb) {
          order: 1;
        }
      }
    }
  }
  // pattern-valign
  &[class*="-valign"] {
    li {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }
  // pattern-margin
  &[class*="-margin"] {
    li {
      margin-bottom: 2em;
    }
  }
}
[class*="col-column2"] {
  li {
    width: 47.5%;
    @include mq_down(mb) {
      width: 100%;
    }
  }
}
[class*="col-column3"] {
  li {
    width: 30%;
    margin-bottom: 2em;
    @include mq_down(mb) {
      width: 100%;
    }
  }
}
[class*="col-column4"] {
  li {
    width: 22.5%;
    @include mq_down(mb) {
      width: 47.5%;
    }
  }
}

[class*="col-column3"],
[class*="col-column4"] {
  &::after {
    content: "";
    display: block;
    width: 30%;
    height: 0;
  }
  li {
    .col-info {
      margin-top: 2em;
      + h3 {
        margin-top: 0.5em;
        font-size: 1.6rem;
      }
    }
    p {
      font-size: 1.35rem;
    }
  }
}