/**
 * animation canvas
 */

.col-animation {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
  background: $white;
  canvas {
    width: 100%;
    height: 100%;
    background: url(/assets/img/animation/bg-animation.png) no-repeat center;//image
    // background: burlywood;
    background-size: cover;
  }
}
