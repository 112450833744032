/**
 * contents
 */

.col-contents {
  position: relative;
  overflow: hidden;
  @include mq_up(sp) {
    padding-left: 8rem;
  }
  img {
    max-width: 100%;
  }
  h2, h3, h4, h5, h6 {
    margin-bottom: 1em;
    font-weight: 500;
    sup,
    small {
      display: block;
      font-size: 1.4rem;
      @include mq_down(sp) {
        font-size: 1.2rem;
      }
    }
    sup {
      margin-top: 1em;
    }
    .col-number {
      display: block;
      font-size: 2.4rem;
      color: $pink;
    }
  }
  h2 {
    font-size: 3.2rem;
    @include mq_down(sp) {
      font-size: 2.4rem;
    }
  }
  h3 {
    font-size: 2.4rem;
    @include mq_down(sp) {
      font-size: 1.8rem;
    }
  }
  h4, h5, h6 {
    font-size: 2rem;
    @include mq_down(sp) {
      font-size: 1.6rem;
    }
  }
  p {
    line-height: 2;
    margin-bottom: 1.5em;
  }
  .col-line {
    margin: 8rem 0;
    border-bottom: solid $graydd 1px;
    @include mq_down(sp) {
      margin: 4rem 0;
    }
  }
  [class*=col-background] {
    @include childMargin;//_utility.scss
    background: $white;
    padding: 8rem 0;
    @include mq_down(sp) {
      padding: 4rem 0;
    }
    .col-sentence {
      @include siteBox;//_utility.scss
      padding: 0 $space;
    }
    .col-overlap {
      @include childMargin;//_utility.scss
      position: relative;
      top: -12rem;
      margin-bottom: -12rem!important;
      @include mq_down(sp) {
        top: -8rem;
        margin-bottom: -8rem!important;
      }
    }
  }
  .col-background-gray {
    background: $grayf7;
  }
  .col-background-lightpurpleblue {
    background: $lightpurpleblue;
  }
}

/**
 * lang
 */

.col-lang-ja {
  margin-right: 0.5em;
  text-decoration: underline;
  transition: $animation;
}
.col-lang-en {
  margin-left: 0.5em;
  text-decoration: underline;
  transition: $animation;
}
html[lang="ja"] {
  .col-lang-en {
    opacity: 0.3;
    text-decoration: none;
  }
  a {
    &:hover {
      .col-lang-ja {
        opacity: 0.3;
        text-decoration: none;
      }
      .col-lang-en {
        opacity: 1;
        text-decoration: underline;
      }
    }
  }
}
html[lang="en"] {
  .col-lang-ja {
    opacity: 0.3;
    text-decoration: none;
  }
  a {
    &:hover {
      .col-lang-ja {
        opacity: 1;
        text-decoration: underline;
      }
      .col-lang-en {
        opacity: 0.3;
        text-decoration: none;
      }
    }
  }
}

/**
 * text
 */

.col-center {text-align: center!important;}
.col-right {text-align: right!important;}
.col-left {text-align: left!important;}

.col-center-pc {@include mq_up(sp) {text-align: center!important;}}
.col-right-pc {@include mq_up(sp) {text-align: right!important;}}
.col-left-pc {@include mq_up(sp) {text-align: left!important;}}

.col-center-sp {@include mq_down(sp) {text-align: center!important;}}
.col-right-sp {@include mq_down(sp) {text-align: right!important;}}
.col-left-sp {@include mq_down(sp) {text-align: left!important;}}

h2.col-lead {
  font-size: 12rem;
  line-height: 0.8;
  font-weight: 500;
  font-family: $fontSpecific;
  @include mq_down(sp) {
    font-size: 6rem;
  }
  i {
    color: $pink;
  }
  small {
    display: block;
    margin-top: 4rem;
    font-weight: bold;
    font-size: 1.6rem;
    line-height: 2;
    letter-spacing: 0.05em;
    @include mq_down(sp) {
      margin-top: 2rem;
      font-size: 1.4rem;
    }
  }
}

p.col-info {
  margin: 0;
  padding: 1.5em 0;
  line-height: 1;
  font-size: 1.3rem;
  &:first-of-type {
    padding-top: 0;
  }
  &:last-of-type {
    padding-bottom: 0;
  }
  time {
    font-weight: bold;
    margin-right: 1.2em;
  }
  span {
    display: inline-block;
    margin: 0 1em 0.5em 0;
    color: $gray66;
    &:first-of-type {
      padding-left: 1em;
      border-left: solid rgba($color: $gray66, $alpha: 0.5) 1px;
    }
    &::before {
      content: "#";
    }
  }
}

/**
 * badge
 */

.col-badge {
  display: flex;
  justify-content: center;
  align-items: center;
  background: $yellow;
  font-weight: 700;
  font-size: 1.4rem;
  width: 8rem;
  height: 8rem;
  border-radius: 50%;
  position: absolute;
  top: -2rem;
  left: -2rem;
  z-index: 1;
  transition: $animation;
  transform: rotate(-15deg);
  backface-visibility: hidden;
  @include mq_down(sp) {
    width: 6rem;
    height: 6rem;
    font-size: 1.2rem;
    top: -1rem;
    left: -1rem;
    }
}
a {
  .col-badge {
    color: $black;
  }
  &:hover {
    .col-badge {
      transform: rotate(0);
    }
  }
}

/**
 * icon-plus
 */

.col-icon-plus {
  display: inline-block;
  background: $white;
  width: 2rem;
  height: 2rem;
  border: solid $green 2px;
  border-radius: 50%;
  position: absolute;
  top: calc(50% - 1rem);
  right: 0;
  vertical-align: middle;
  transition: $animation;
  &::before,
  &::after {
    content: "";
    display: block;
    width: 60%;
    height: 2px;
    background: $green;
    position: absolute;
    top: calc(50% - 1px);
    left: 20%;
    transition: $animation;
  }
  &::after {
    transform: rotate(90deg);
  }
}
.js-on {
  .col-icon-plus {
    background: $green;
    &::before,
    &::after {
      background: $grayf1;
    }
    &::after {
      transform: rotate(0);
    }
  }
}

/**
 * trim
 */

[class*="col-trim"] {
  position: relative;
  width: 100%;
  overflow: hidden;
  &::before {
    content:"";
    display: block;
    padding-top: 62.5%;
  }
  img {
    min-width: 100%;
    min-height: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    object-fit: cover;
  }
  &[class*="-line"] {
    border: solid $grayf1 1px;
  }
  &[class*="-thin"] {
    &::before {
      padding-top: 35%;
    }
  }
}
a {
  &:hover {
    [class*="col-trim"] {
      img {
        transform: translate(-50%, -50%) scale(1.1);
        opacity: 1;
      }
    }
  }
}


/**
 * user
 */

.col-user {
  display: block;
  font-size: 1.4rem;
  font-weight: bold;
  margin-top: 1em;
  letter-spacing: 0.05em;
  img {
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
    vertical-align: middle;
    margin-right: 1em;
  }
}

/**
 * hide
 */

.col-hide {
  display: none;
}
.col-hide-pc {
  display: none;
  @include mq_down(sp) {
    display: inherit;
  }
}
.col-hide-sp {
  display: inherit;
  @include mq_down(sp) {
    display: none;
  }
}
