/**
 * article site
 */

[class*="col-article"] {
  &[class*="-site"] {
    .col-box-body {
      max-width: 90rem;
    }
  }
}