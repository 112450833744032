/**
 * button
 */

[class*="col-button"] {
  width: 100%;
  max-width: 24rem;
  display: block;
  border-radius: 5rem;
  border: solid $green 1px;
  background: $white;
  padding: 1em 2em;
  text-align: center;
  color: $green!important;
  font-weight: bold;
  font-size: 1.4rem;
  letter-spacing: 0.05em;
  transition: $animation;
  [class*="col-arrow"] {
    margin: 0 0 0.1em 0.5em;
    transition: $animation;
  }
  svg {
    position: relative;
    display: inline-block;
    fill: $green;
    width: 1em;
    height: 0.75em;
    margin: 0 0.25em;
    left: 0;
  }
  &:hover {
    background: $green;
    color: $white!important;
    svg {
      fill: $white;
      left: 0.5em;
    }
  }
  &[target*="_blank"] {
    svg {
      height: 0.85em;
      top: 0.1rem;
    }
  }
  // color
  &[class*=-color] {
    background: $green;
    color: $white!important;
    svg {
      fill: $white;
    }
    &:hover {
      background: $white;
      color: $green!important;
      svg {
        fill: $green;
      }
    }
  }
  // line
  &[class*="-line"] {
    background: none;
    border-color: $white;
    color: $white!important;
    svg {
      fill: $white;
    }
    &:hover {
      background: $white;
      color: $black!important;
      svg {
        fill: $black;
      }
    }
  }
}
.col-center {
  [class*="col-button"] {
    margin: auto;
  }
}
.col-center-sp {
  [class*="col-button"] {
    @include mq_down(sp) {
      margin: auto;
    }
  }
}
