/**
 * Reset
 */

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
article, aside, details, figcaption, figure, header, footer, hgroup, menu, nav, section {
  display: block;
}
ol, ul {
  list-style: none;
}
hr {
  height: 0;
  border: 0;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
/**
 * Base
 */

// ページのフェードイン設定
@keyframes page_fadeIn {
  from { opacity: 0;}
  to { opacity: 1;}
}
html {
  font-size: 10px;
  scroll-behavior: smooth;
  background: $black;
}
body{
  animation-name: page_fadeIn;
  animation-duration: 2s;
  font-family: $fontGothic;
  font-size: 1.6rem;
  @include mq_down(sp) {
    font-size: 1.4rem;
  }
}
a {
  color: $black;
  text-decoration: none;
  transition: $animation;
  &:hover,
  &:active,
  &:focus {
    color: $green;
    text-decoration: none;
  }
  img, svg {
    transition: $animation;
  }
  &:hover {
    img, svg {
      opacity: 0.75;
    }
  }
}
::selection {
  background: $black;
  color: $white;
}
::-moz-selection {
  background: $black;
  color: $white;
}

.col-outline {
  * {
    outline: 1px solid $pink;
  }
}
