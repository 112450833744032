/**
 * fonts icon
 */

@import url('https://cdn.jsdelivr.net/npm/yakuhanjp@3.2.0/dist/css/yakuhanjp.min.css');// YakuHanJP
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;500;700&display=swap');// 'Noto Sans JP'
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500&display=swap');// 'Roboto'

/**
 * variables
 */

// color
$blackdark: #111;
$black: #333;
$white: #fff;
$grayf7: #f7f7f7;
$grayf1: #f1f1f1;
$graydd: #ddd;
$grayaa: #aaa;
$gray66: #666;

$green: #007B97;
$pink: #fd635f;
$yellow: #ffe861;

$lightgreen: #e6f9fa;
$lightyellow: #fffbd1;
$lightpink: #ffefef;
$lightpurpleblue: #e7ebf2;
$lightpurplered: #fcf0ff;

$facebook: #3b5998;
$twitter: #1da1f2;

// fonts
$fontGothic: YakuHanJP, 'Roboto', 'Noto Sans JP', 'Hiragino Kaku Gothic Pro', Meiryo, sans-serif;
$fontSpecific: YakuHanJP, 'Founders Grotesk', 'Roboto', 'Noto Sans JP', 'Hiragino Kaku Gothic Pro', Meiryo, sans-serif;

// base style
$siteWidth: 144rem;
$siteMin: $siteWidth / 2;
$space: 5%;
$radius: 0.8rem;
$shadow: 0 0 0.5rem rgba(0,0,0,0.1);
$animation: all 0.5s ease;
