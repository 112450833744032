/**
 * article
 */

[class*="col-article"] {
  @include childMargin;//_utility.scss
  .col-box-body {
    @include childMargin;//_utility.scss
    margin-bottom: 8rem;
    @include mq_down(sp) {
      margin-bottom: 4rem;
    }
    h2, h3, h4, h5, h6 {
      margin-top: 2em;
    }
    h3 {
      padding-bottom: 0.5em;
      border-bottom: solid $black 2px;
    }
    h4 {
      font-size: 2.4rem;
      @include mq_down(sp) {
        font-size: 1.8rem;
      }
    }
    hr {
      margin: 3em 0;
      border-bottom: solid $grayf1 1px;
    }
    // list
    ul, ol {
      margin-bottom: 1.5em;
      li {
        position: relative;
        margin-bottom: 0.5em;
        padding-left: 3rem;
        &::before {
          display: inline-block;
          width: 1.5em;
          text-align: center;
          position: absolute;
          left: 0;
        }
      }
    }
    ul {
      li {
        &::before {
          content: "・ ";
          font-weight: 700;
        }
      }
    }
    ol {
      li {
        @for $i from 1 through 20 {
          &:nth-child(#{$i}) {
            &::before {
              content: "#{$i}. ";
              font-weight: 700;
            }
          }
        }
      }
    }
    // link
    a {
      color: $green;
      &:hover {
        opacity: 0.75;
      }
      &[target*="_blank"] {
        &::after {
          content: "";
          display: inline-block;
          width: 0.7em;
          height: 0.7em;
          margin-left: 0.5em;
          background: url(/assets/img/common/icon-window.svg);
          vertical-align: middle;
        }
      }
    }
    // image
    figure {
      margin: 3em 0;
      text-align: center;
      img {
        display: block;
        margin: auto;
        border: solid $grayf1 1px;
      }
      figcaption {
        margin-top: 1.5em;
        color: $grayaa;
        font-size: 1.35rem;
      }
      &.col-noline {
        img {
          border: none;
        }
      }
    }
    // table
    table {
      width: 100%;
      margin: 3em 0;
      font-size: 1.4rem;
      line-height: 2;
      background: $white;
      th, td {
        border: solid $graydd 1px;
        padding: 0.75em 1em;
      }
      th {
        background: $grayf7;
      }
      caption {
        margin-bottom: 1em;
        color: $grayaa;
        font-size: 1.35rem;
      }
    }
    .col-table-scroll {
      @include mq_down(sp) {
        overflow-x: scroll;
        margin: 3em 0;
      }
      table {
        caption-side: bottom;
        max-width: $siteWidth;
        min-width: $siteMin;
        margin: 0 0 1em;
        caption {
          margin: 1em 0 0;
          @include mq_up(mb) {
            display: none;
          }
          @include mq_down(mb) {
            width: 90vw;
          }
        }
      }
    }
    // blockquote
    blockquote {
      @include childMargin;//_utility.scss
      background: $grayf7;
      border-radius: $radius / 2;
      margin: 3em 0;
      padding: 2.5em;
      position: relative;
      @include mq_down(sp) {
        padding: 2em;
      }
      &::before,
      &::after {
        display: block;
        font-size: 10rem;
        color: $graydd;
        font-family: 'Inter';
        position: absolute;
        @include mq_down(sp) {
          font-size: 8rem;
        }
      }
      &::before {
        content: "“";
        top: -0.45em;
        left: 0;
      }
      &::after {
        content: "”";
        right: 0;
        bottom: -1em;
      }
    }
    // card
    .embedly-card {
      margin: 3em 0;
      .embedly-card-hug {
        max-width: none!important;
        iframe {
          max-width: none!important;
          width: 100%;
          background: $grayf7;
          padding: 0.5em 1em!important;
          border-radius: $radius / 2;
        }
      }
    }
    // column
    .col-column {
      display: flex;
      flex-wrap: wrap;
      margin: 0 -1em;
      > * {
        flex: 1;
        min-width: calc(100% / 3);
        padding: 0 1em 2em;
        figure {
          margin: 0;
          + p {
            margin-top: 1em;
          }
        }
        img {
          width: 100%;
        }
        p {
          font-size: 1.4rem;
          line-height: 1.6;
        }
        &:nth-child(n + 4) {
          max-width: calc(100% / 3);
        }
      }
    }
  }
  // pre code
  pre {
    margin: 3em 0;
    background: $black;
    color: $white;
    border-radius: $radius / 2;
    padding: 1.5em 2em;
  }
  // link blank
  .col-link {
    border-top: solid $grayf1 1px;
    padding: 4rem 0 6rem;
    ul {
      display: flex;
      flex-wrap: wrap;
      li {
        width: 50%;
        margin-bottom: 1em;
        @include mq_down(sp) {
          width: 100%;
        }
        &:nth-child(odd) {
          padding-right: 1em;
          @include mq_down(sp) {
            padding-right: 0;
          }
        }
        &:nth-child(even) {
          padding-left: 1em;
          @include mq_down(sp) {
            padding-left: 0;
          }
        }
        a {
          display: block;
          padding: 2rem 6rem 2rem 3rem;
          border: solid $green 1px;
          border-radius: $radius / 2;
          color: $green;
          font-weight: 500;
          line-height: 1.2;
          position: relative;
          font-size: 1.6rem;
          overflow: hidden;
          @include mq_down(mb) {
            font-size: 1.2rem;
          }
          &::before {
            content: "";
            width: 3.6em;
            height: 100%;
            display: block;
            background: $white;
            position: absolute;
            top: 0;
            right: 0;
            transition: $animation;
          }
          svg {
            content: "";
            position: absolute;
            width: 1em;
            height: 1em;
            fill: $green;
            top: calc(50% - 0.5em);
            right: 2rem;
            transition: $animation;
          }
          &:hover {
            background: $green;
            color: $white;
            &::before {
              background: $green;
            }
            svg {
              fill: $white;
              opacity: 1;
            }
          }
        }
      }
    }
  }
}

/**
 * back
 */

.col-back {
  width: 12em;
  display: block;
  margin: 6rem auto 0;
  color: $green;
  font-weight: bold;
  font-size: 1.4rem;
  text-align: center;
  letter-spacing: 0.05em;
  @include mq_down(sp) {
    margin: 4rem auto 0;
  }
  svg {
    display: block;
    fill: $green;
    width: 4rem;
    margin: 0 auto 1em;
  }
  &:hover {
    svg {
      opacity: 1;
      transform: rotateY(180deg);
    }
  }
}

/**
 * profile
 */

.col-profile {
  border-top: solid $grayf1 1px;
  padding: 4rem 0;
  h3 {
    margin-bottom: 2em;
  }
  ul {
    display: flex;
    flex-wrap: wrap;
    li {
      width: 25%;
      margin-bottom: 2em;
      @include mq_down(sp) {
        width: calc(100% / 3);
      }
      @include mq_down(mb) {
        width: 50%;
      }
      a {
        display: flex;
        align-items: center;
        color: $green;
        font-weight: 500;
        @include mq_down(mb) {
          text-align: center;
          flex-direction: column;
        }
        &:hover {
          color: $black;
        }
        .col-trim {
          width: 8rem;
          border-radius: $radius / 2;
          @include mq_down(mb) {
            margin-bottom: 1em;
          }
          &::before {
            padding-top: 100%;
          }
        }
        p {
          width: calc(100% - 8rem);
          margin-bottom: 0;
          padding: 0 1em;
          line-height: 1.4;
          @include mq_down(mb) {
            width: 100%;
          }
          small {
            display: block;
            font-size: 1.2rem;
            color: $grayaa;
            @include mq_down(mb) {
              font-size: 1.1rem;
            }
          }
        }
        &:not([href]) {
          color: $black;
          &:hover {
            .col-trim {
              img {
                transform: translate(-50%, -50%);
              }
            }
          }  
        }
      }
    }
  }
}

/**
 * writer
 */

.col-writer {
  @include childMargin;//_utility.scss
  max-width: $siteMin;
  border-top: solid $grayf1 1px;
  margin: auto;
  padding: 4rem 0 8rem;
  @include mq_down(sp) {
    padding: 4rem 0;
  }
  h3 {
    text-align: center;
  }
  h4 {
    font-size: 1.6rem;
    margin-bottom: 0.5em;
    small {
      font-size: 1.2rem;
      color: $grayaa;
    }
  }
  a {
    display: flex;
    align-items: flex-start;
    color: $green;
    margin-bottom: 3em;
    @include mq_down(mb) {
    }
    &:hover {
      color: $black;
    }
    .col-trim {
      width: 12rem;
      border-radius: $radius / 2;
      @include mq_down(mb) {
        width: 8rem;
      }
      &::before {
        padding-top: 100%;
      }
    }
    .col-text {
      width: calc(100% - 12rem);
      padding-left: 2.5em;
      @include mq_down(sp) {
        width: calc(100% - 8rem);
        padding-left: 1.5em;
      }
      p {
        font-size: 1.35rem;
        margin-bottom: 0;
        color: $black;
      }
    }
    &:not([href]) {
      color: $black;
      &:hover {
        .col-trim {
          img {
            transform: translate(-50%, -50%);
          }
        }
      }  
    }
  }
}

/**
 * social
 */

.col-social {
  background: $grayf7;
  padding: $space * 0.5 0 $space * 0.75 ;
  h4 {
    text-align: center;
  }
  ul {
    display: flex;
    justify-content: center;
    li {
      margin: 0.75em;
      a {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 6rem;
        height: 6rem;
        background: $white;
        border: solid $black 2px;
        border-radius: 50%;
        svg {
          width: 50%;
        }
        &:hover {
          border: solid $green 2px;
          svg {
            fill: $green;
          }
        }
        &.col-twitter {
          &:hover {
            border-color: $twitter;
            svg {
              opacity: 1;
              fill: $twitter;
            }
          }
        }
        &.col-facebook {
          &:hover {
            border-color: $facebook;
            svg {
              opacity: 1;
              fill: $facebook;
            }
          }
        }
      }
    }
  }
}

/**
 * pager
 */

.col-pager {
  border-top: solid $grayf1 1px;
  border-bottom: solid $grayf1 1px;
  ul {
    display: flex;
    @include mq_down(mb) {
      flex-direction: column;
    }
    li {
      width: 50%;
      position: relative;
      overflow: hidden;
      @include mq_down(mb) {
        width: 100%;
      }
      &:first-child {
        &::after {
          content: "";
          display: block;
          width: 1px;
          height: calc(100% - 4rem);
          background: $grayf1;
          position: absolute;
          top: 2rem;
          right: 0;
          @include mq_down(mb) {
            width: 90%;
            height: 1px;
            top: calc(100% - 1px);
            right: $space;
          }
        }
      }
      a {
        display: flex;
        align-items: center;
        height: 100%;
        font-size: 2rem;
        padding: 4em;
        position: relative;
        background: rgba($color: $white, $alpha: 0.85);
        @include mq_down(mb) {
          font-size: 1.6rem;
          padding: 3.5em;
        }
      }
      // prev next
      &.col-prev,
      &.col-next {
        sup {
          position: absolute;
          top: 2rem;
          font-size: 1.4rem;
          font-weight: 500;
          color: $gray66;
        }
        svg {
          width: 1em;
          height: 1em;
          fill: $black;
          position: absolute;
          top: calc(50% - 0.4em);
        }
        a {
          &:hover {
            svg {
              fill: $green;
            }
          }
        }
      }
      &.col-prev {
        sup, svg {
          left: 4rem;
          @include mq_down(mb) {
            left: $space;
          }
        }
        svg {
          transform: rotate(-180deg);
        }
        a {
          &:hover {
            svg {
              transform: rotate(-180deg) translate(0.25em, 0);
            }
          }
        }
      }
      &.col-next {
        sup, svg {
          right: 4rem;
          @include mq_down(mb) {
            right: $space;
          }
        }
        a {
          &:hover {
            svg {
              transform: translate(0.25em, 0);
            }
          }
        }
      }
      img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        // filter: blur(10px) contrast(140%) brightness(140%);
        // transform: scale(1.1) translate(-50%, -50%);
      }
    }
  }
}

/**
 * quote
 */

.col-quote {
  @include childMargin;//_utility.scss
  display: flex;
  align-items: center;
  background: $grayf7;
  border-radius: $radius / 2;
  margin: 3em 0;
  padding: 1.1em;
  position: relative;
  overflow: hidden;
  @include mq_down(mb) {
    align-items: flex-start;
  }
  .col-trim {
    width: 30%;
    margin-right: 1em;
  }
  dl {
    width: 70%;
    overflow: hidden;
    color: $black;
    transition: $animation;
    dt {
      font-size: 1.3rem;
      color: $gray66;
      margin-bottom: 0.5em;
      line-height: 1;
    }
    dd {
      font-weight: 400;
      font-size: 1.8rem;
      @include mq_down(sp) {
        font-size: 1.4rem;
      }
    }
  }
  &:hover {
    opacity: 1!important;
    dl {
      color: $green;
    }
  }
}