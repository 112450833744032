/**
 * image trimming
 */

@mixin image_calculation {
  display: block;
  position: relative;
  z-index: 0;
  img{
    position: absolute;
    top: 0;
    object-fit: cover;
    width: 100%;
    height: 100%;  
  }
  &::before {
    content: '';
    display: block;
    padding-top: 62.5%;// 縦:横=1:1.6の場合62.5%
  }
}
.col-trim {
  @include image_calculation;//_utility.scss
}

/**
 * breakpoint
 */

$breakpoint_up: (// xxx以上
  'sp': 'screen and (min-width: 1120px)',
  'mb': 'screen and (min-width: 680px)',  
) !default;
$breakpoint_down: (// xxx未満
  'sp': 'screen and (max-width: 1119px)',
  'mb': 'screen and (max-width: 679px)',
) !default;
// ブレイクポイントmixinの定義
@mixin mq_up($breakpoint: md) {// xxx以上
  @media #{map_get($breakpoint_up, $breakpoint)} {
    @content;
  }
}
@mixin mq_down($breakpoint: md) {// xxx未満
  @media #{map_get($breakpoint_down, $breakpoint)} {
    @content;
  }
}

/**
 * @include siteBox;//_utility.scss
 */

@mixin siteBox {
  width: 100%;
  max-width: $siteWidth;
  margin: auto;
  padding: $space;
}

/**
 * @include posCenter;//_utility.scss
 */

@mixin posCenter {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%,);
}

// 内包する最初と最後の要素のマージンの調整
// @include childMargin;//_utility.scss
@mixin childMargin {
  & > *:first-child { margin-top: 0!important;}
  & > *:last-child { margin-bottom: 0!important;}
}