/**
 * header
 */

[class*="col-header"] {
  position: relative;
  padding: 16rem 0 12rem;
  background: linear-gradient(to bottom, rgba($color: $white, $alpha: 0.2) 50%,  rgba($color: $white, $alpha: 0.8));
  overflow: hidden;
  @include mq_down(sp) {
    padding: 20rem $space 12rem;
  }
  h1 {
    max-width: $siteWidth;
    margin: auto;
    padding: 0 $space;
    text-align: center;
    font-size: 6rem;
    font-family: $fontSpecific;
    font-weight: 500;
    letter-spacing: 0.03em;
    position: relative;
    @include mq_down(sp) {
      font-size: 4.8rem;
    }
    p {
      font-size: 65%;
    }
    time {
      display: block;
      font-size: 1.6rem;
      margin-bottom: 1.5em;
      @include mq_down(sp) {
        margin-bottom: 0.5em;
      }
    }
    small {
      display: block;
      font-size: 1.6rem;
      font-weight: normal;
      letter-spacing: 0.02em;
      line-height: 2;
      @include mq_down(sp) {
        font-size: 1.4rem;
      }
    }
    .col-catecory {
      a {
        font-size: 1.4rem;
        color: $green;
        margin-right: 1em;
        &::before {
          content: "#";
        }
        &:hover {
          color: $black;
        }
      }
    }
  }
  > img {
    min-width: 100%;
    min-height: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 0;
    transform: translate(-50%, -50%);
    pointer-events: none;
    opacity: 0.3;
    @include mq_down(sp) {
      max-width: unset!important;
    }
  }
  // ページのフェードイン設定
  @keyframes font_fadeIn {
    0%, 50% { opacity: 0;}
    100% { opacity: 0.1;}
  }
  sup {
    font-size: 12rem;
    font-weight: 500;
    font-family: $fontSpecific;
    position: absolute;
    top: 0.5em;
    right: $space;
    opacity: 0.1;
    pointer-events: none;
    color: $black;
    animation-name: font_fadeIn;
    animation-duration: 2s;
    @include mq_down(sp) {
      top: 1.8em;
      right: $space * 2;
      font-size: 6rem;
    }
    &.background--dark {
      color: $white;
    }
  }

  // pattern dark
  &[class*="-dark"],
  &[class*="-light"],
  &[class*="-slide"] {
    color: $white;
    background: $blackdark;
    h1 {
      text-align: inherit;
      font-size: 3.6rem;
      font-weight: 500;
      @include mq_down(sp) {
        font-size: 2.4rem;
      }
      small {
        display: block;
        font-size: 1.6rem;
        margin-top: 1.5em;
        @include mq_down(sp) {
          font-size: 1.4rem;
        }
      }
    }
  }

  // pattern light blur
  &[class*="-light"],
  &[class*="-blur"] {
    color: inherit;
    background: $white;
    img {
      opacity: 0.2;
      filter: blur(10px) contrast(140%) brightness(140%);
      transform: scale(1.1) translate(-50%, -50%);
    }
  }
  &[class*="-light"] {
    background: $grayf7;
    padding: 16rem 0 4rem;
    @include mq_down(sp) {
      padding: 16rem 0 4rem;
    }
  }
  &[class*="-blur"] {
    padding: 16rem 0 16rem;
    @include mq_down(sp) {
      padding: 16rem 0 12rem;
    }
  }

  // pattern gray
  &[class*="-gray"] {
    background: $grayf7;
  }

  // pattern slide
  &[class*="-slide"] {
    padding: 18rem 0 8rem;
    @include mq_down(sp) {
      padding: 17rem 0 4rem;
    }
  }

}
