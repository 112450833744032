/**
 * dots
 */

.slick-dots {
  li {
    margin: 0;
    button {
      &::before {
        content: "";
        width: 1rem;
        height: 1rem;
        margin: 0.5rem;
        border-radius: 50%;
        border: solid $green 1px;
        box-sizing: border-box;
        opacity: 1;
      }
    }
    &.slick-active {
      button {
        &::before {
          background: $green;
        }
      }
    }
  }
}
