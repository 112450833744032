/**
 * selector
 */

.col-filtering {
  background: $grayf7;
  padding: $space * 0.5 $space;
  text-align: center;
  @include mq_down(sp) {
    text-align: inherit;
    padding: $space;
  }
  label {
    &:first-of-type {
      margin-left: 0!important;
    }
    &:last-of-type {
      margin-right: 0!important;
    }
  }
  // select
  [class*="col-select"] {
    display: inline-block;
    position: relative;
    margin: 0 1em;
    @include mq_down(mb) {
      width: 100%;
      margin: 0.75em 0;
    }
    &::after {
      content: "";
      width: 0.4em;
      height: 0.4em;
      position: absolute;
      top: calc(50% - 0.2em);
      right: 1.5em;
      border-right: solid $green 2px;
      border-bottom: solid $green 2px;
      transform: rotate(45deg);
    }
    &[class*="-margin"] {
      margin: 0 2em;
    }
  }
  select {
    border-radius: $radius / 2;
    border: solid $white 2px;
    border-bottom: solid $graydd 2px;
    padding: 1.25em;
    min-width: 24rem;
    color: $green;
    font-weight: 600;
    appearance: none;
    transition: $animation;
    outline: none;
    cursor: pointer;
    @include mq_down(mb) {
      width: 100%;
    }
    &:hover {
      border-bottom: solid $grayaa 2px;
    }
  }
  // radio
  .col-radio {
    display: inline-block;
    margin-right: 1em;
    padding: 0.75em 0.5em 0.75em 1.5em;
    position: relative;
    transition: $animation;
    cursor: pointer;
    span {
      &::before,
      &::after {
        content: "";
        display: inline-block;
        width: 1.8rem;
        height: 1.8rem;
        position: absolute;
        top: calc(50% - 0.8rem);
        left: 0;
        border-radius: 50%;
        box-sizing: border-box;
      }
      &::before {
        background: $white;
        border: solid $green 1px;
      }
      &::after {
        width: 1.2rem;
        height: 1.2rem;
        background: $white;
        top: calc(50% - 0.5rem);
        left: 0.2em;
        transition: $animation;
      }
    }
    input[type="radio"] {
      display: none;
      &:checked {
        + span {
          &::after {
            background: $green;
          }
        }
      }
    }
    &:hover {
      color: $green;
    }
  }
  .col-background & {
    margin-bottom: 6rem;
    @include mq_down(sp) {
      margin-bottom: $space;
    }
  }
}
