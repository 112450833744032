/**
 * anchor
 */

[class*=col-anchor] {
  background: $grayf7;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 6rem;
  border-radius: $radius / 2;
  overflow: hidden;
  @include mq_down(sp) {
    flex-direction: column;
  }
  li {
    flex: 1;
    min-width: 25%;
    position: relative;
    &:nth-child(n + 5) {
      @include mq_up(sp) {
        max-width: 25%;
      }
    }
    &::after {
      content: "";
      position: absolute;
      width: 1px;
      height: calc(100% - 3rem);
      background: $grayf1;
      top: 1.5rem;
      right: 0;
      @include mq_down(sp) {
        width: calc(100% - 3rem);
        height: 1px;
        top: inherit;
        right: 1.5rem;
        bottom: 0;
      }
    }
    &:last-child {
      &::after {
        display: none;
      }
    }
    &:nth-child(4n) {
      &::after {
        @include mq_up(sp) {
          display: none;
        }
      }
    }
    a {
      display: flex;
      align-items: center;  
      font-size: 1.4rem;
      font-weight: bold;
      color: $green;
      padding: 2em;
      position: relative;
      @include mq_up(sp) {
        flex-direction: column;
        justify-content: center;
        height: 100%;
        text-align: center;
      }
      @include mq_down(sp) {
        padding: 1.5em;
      }
      &:hover {
        background: $grayf1;
      }
    }
    svg {
      width: 4rem;
      min-width: 4rem;
      display: block;
      fill: $green;
      margin-bottom: 0.5em;
      @include mq_down(sp) {
        margin-bottom: 0;
        margin-right: 1em;
      }
    }
  }
}

.col-anchor-arrow {
  li {
    a {
      @include mq_up(sp) {
        padding: 1.5em 2em 2.5em;
      }
      @include mq_down(sp) {
        padding: 1.5em 3em 1.5em 1.5em;
      }
      &::after {
        content: "";
        width: 0.6rem;
        height: 0.6rem;
        display: block;
        position: absolute;
        border-right: solid $green 2px;
        border-bottom: solid $green 2px;
        right: 50%;
        bottom: 1.5rem;
        transform: rotate(45deg) translate(-50%, 0);
        @include mq_down(sp) {
          right: $space;
          bottom: calc(50% - 0.5em);
        }
      }
    }
  }
}
